window.addEventListener("load", function() {

    var _hsp = window._hsp = window._hsp || [];

    _hsp.push(['addPrivacyConsentListener', function(consent) {
        console.log(`analytics: ${consent.categories.analytics}`);
        console.log(`advertisement: ${consent.categories.advertisement}`);
        console.log(`functionality: ${consent.categories.functionality}`);
        
        window.dataLayer.push({
            'event' : 'set_analytics_consent',
            'analytics_consent' : consent.categories.analytics
        });
        
        window.dataLayer.push({
            'event' : 'set_ads_consent',
            'ads_consent' : consent.categories.advertisement
        });
        
        window.dataLayer.push({
            'event' : 'set_functionality_consent',
            'functionality_consent' : consent.categories.functionality
        });

        // Setting the cookie using vanilla JS
        if (document.cookie !== undefined) {
            document.cookie = `consent_status=${JSON.stringify({
                'analytics': consent.categories.analytics, 
                'ads': consent.categories.advertisement, 
                'functionality': consent.categories.functionality
            })}; path=/;`;
        }
    }]);

});